import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {    
     
    fetchUsers(ctx, queryParams) { 
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + '/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + `/userById?user_id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }, 
    addUser(ctx, userData) {
      var bodyFormData = new FormData();
      bodyFormData.append('user_username', userData.user_username);
      bodyFormData.append('user_name', userData.user_name);
      bodyFormData.append('user_password', userData.user_password);
      bodyFormData.append('user_status', userData.user_status);
      bodyFormData.append('user_created_by', userData.user_created_by); 
      bodyFormData.append('user_role', userData.user_role); 
      bodyFormData.append('user_email', userData.user_email ?? ""); 
      bodyFormData.append('user_mobile', userData.user_mobile ?? ""); 
      
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_HOST + '/user',
          bodyFormData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      var bodyFormData = new FormData();
      bodyFormData.append('user_username', userData.user_username);
      bodyFormData.append('user_name', userData.user_name);
      bodyFormData.append('user_status', userData.user_status);
      bodyFormData.append('user_updated_by', userData.user_created_by); 
      bodyFormData.append('user_id', userData.user_id); 
      bodyFormData.append('user_role', userData.user_role); 
      bodyFormData.append('user_email', userData.user_email ?? ""); 
      bodyFormData.append('user_mobile', userData.user_mobile ?? ""); 
      
      if(userData.user_password != undefined && userData.user_password != '' )
      {
        bodyFormData.append('user_password', userData.user_password); 
      }
      
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_HOST + '/user',
          bodyFormData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
