import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {    
    fetchEntities(ctx, queryParams) { 
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + '/getEntityName')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchAddress(ctx, queryParams) { 
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + '/getpostalcode?address=' + queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }, 
    fetchQuotes(ctx, queryParams) { 
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + '/quotations?entity_name='+queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }, 
    fetchQuoteDetails(ctx, {job_entity_name,job_quotation_no}) { 
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + '/quotationdetails?entity_name='+job_entity_name+'&quotation_no='+job_quotation_no)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchJobs(ctx, queryParams) { 
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + '/jobs', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchJob(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + `/jobByNo?job_no=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchJobTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + `/jobTaskByJobId?job_id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addJob(ctx, jobData) {
      var bodyFormData = new FormData();
      bodyFormData.append('job_quotation_no', jobData.job_quotation_no);
      bodyFormData.append('job_remark', jobData.job_remark);
      bodyFormData.append('job_address', jobData.job_address);
      bodyFormData.append('job_postal_code', jobData.job_postal_code);
      bodyFormData.append('job_created_by', jobData.job_created_by); 
      bodyFormData.append('job_customer_name', jobData.customerName); 
      bodyFormData.append('job_customer_code', jobData.customerCode); 
      bodyFormData.append('job_entity_name', jobData.job_entity_name);
      bodyFormData.append('job_longtitude', jobData.job_longtitude);
      bodyFormData.append('job_latitude', jobData.job_latitude);
      bodyFormData.append('job_start_date', jobData.job_start_date);
      bodyFormData.append('job_end_date', jobData.job_end_date);
      bodyFormData.append('job_customer_contact_name', jobData.customerContactName);
      bodyFormData.append('job_customer_contact_no', jobData.customerContactNo);
      bodyFormData.append('job_sales_agent', jobData.salesAgent);
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_HOST + '/job',
          bodyFormData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateJob(ctx, jobData) {
      var bodyFormData = new FormData();
      bodyFormData.append('job_remark', jobData.job_remark);
      bodyFormData.append('job_updated_by', jobData.job_created_by); 
      bodyFormData.append('job_start_date', jobData.job_start_date);
      bodyFormData.append('job_end_date', jobData.job_end_date);
      bodyFormData.append('job_status', jobData.job_status);
      bodyFormData.append('job_id', jobData.job_id);
      bodyFormData.append('job_sales_agent', jobData.job_sales_agent);
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_HOST + '/job',
          bodyFormData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addJobTask(ctx, jobTaskData) {
      var bodyFormData = new FormData();
      bodyFormData.append('job_task_description', jobTaskData.job_task_description);
      bodyFormData.append('job_task_remark', jobTaskData.job_task_remark);
      bodyFormData.append('job_task_job_id', jobTaskData.job_task_job_id);
      
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_HOST + '/jobTask',
          bodyFormData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateJobTask(ctx, jobTaskData) {
      var bodyFormData = new FormData();
      bodyFormData.append('job_task_description', jobTaskData.job_task_description);
      bodyFormData.append('job_task_remark', jobTaskData.job_task_remark);
      bodyFormData.append('job_task_id', jobTaskData.job_task_id);
      bodyFormData.append('job_task_status', jobTaskData.job_task_status);
      
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_HOST + '/jobTask',
          bodyFormData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkCloseJob(ctx, { id }) {
      console.log(id,"id here");
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + `/checkCloseById?job_id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
