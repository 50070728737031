<template>

  <div>

    <job-list-add-new
      :is-add-new-job-sidebar-active.sync="isAddNewJobSidebarActive"
      @refetch-data="refetchData"
    /> 

    <!-- Table Container Card -->
    
    <b-card
      no-body
      class="mb-0"
    >
    
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          

          <!-- Search -->
          <b-col
            cols="12"
            md="12"
          >
          <div class="d-flex align-items-center justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block mr-1"
          />
          <b-button
                variant="primary"
                @click="isAddNewJobSidebarActive = true"
              >
                <span class="text-nowrap">Add Job</span>
              </b-button>
        </div>
      </b-form-group>
    </div>
          </b-col>
        </b-row>

      </div>

      
      <div class="mx-2 mb-2"> 
        <b-row>
            <b-col> 
              <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
       class="table b-table"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
    <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span v-if="props.column.field === 'job_start_date'" class="text-nowrap" >  
          <span class="text-nowrap">{{ moment(props.row.job_start_date).format("DD MMM yyyy") }}</span>
        </span>
        
        <span v-else-if="props.column.field === 'job_end_date'" class="text-nowrap" >  
          <span class="text-nowrap">{{ moment(props.row.job_end_date).format("DD MMM yyyy") }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'job_status'">
          <b-badge :variant="statusVariant(props.row.job_status)">
            {{ props.row.job_status }}
          </b-badge>
          <br />
          <span v-if="props.row.job_task_status!=''" style="font-size:12px;">({{props.row.job_task_status}})</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item :to="{ name: 'apps-jobs-edit', params: { id: props.row.job_no } }">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="confirmText(props.row)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                   
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['15','25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
            </b-col> 
        </b-row>
      </div>
    </b-card>
 
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BFormSelect,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BTabs, BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import jobStoreModule from '../jobStoreModule'
import userStoreModule from '../../user/userStoreModule'

import JobListAddNew from './JobListAddNew.vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment';
export default {
  components: { 
    JobListAddNew,
    VueGoodTable,
    moment,
    BTabs, BTab,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormSelect,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      pageLength: 15,
      dir: false, 
      columns: [
        {
          label: 'Job No',
          field: 'job_no',
        },
        {
          label: 'Entity',
          field: 'job_entity_name',
        },{
          label: 'Address',
          field: 'job_address',
        },
        {
          label: 'Contact',
          field: 'job_customer_contact_no',
        }, 
        {
          label: 'Sales Agent',
          field: 'job_sales_agent',
        },
        {
          label: 'Start Date',
          field: 'job_start_date',
        }, 
        {
          label: 'End Date',
          field: 'job_end_date',
        },
        
        {
          label: 'Status',
          field: 'job_status',
        },
        {
          label: 'Actions',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Active',
        2: 'Completed',
        3: 'Cancelled', 
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-info', 
      }],
    }
  },
  methods:{
    moment,
    confirmText(recordRow) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          recordRow.job_status='Deleted';
          store
            .dispatch('app-job/updateJob', recordRow)
            .then(response => {
              this.refetchData();
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: 'Record has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error processing',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
          }
           
      })
    },
    refetchData(){
      store
      .dispatch('app-job/fetchJobs', { })
      .then(response => {
        this.rows = response.data;          
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching jobs list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
  computed: {
    
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active      : 'light-primary',
        Completed : 'light-success', 
        Cancelled      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) { 
        this.dir = true
        return this.dir
      } 
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.refetchData();
  },
  setup() {
    const JOB_APP_STORE_MODULE_NAME = 'app-job'
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(JOB_APP_STORE_MODULE_NAME)) store.registerModule(JOB_APP_STORE_MODULE_NAME, jobStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(JOB_APP_STORE_MODULE_NAME)) store.unregisterModule(JOB_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewJobSidebarActive = ref(false)
 
    return { 
      // Sidebar
      isAddNewJobSidebarActive, 
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
