<template>
  <b-sidebar
    id="add-new-job-sidebar"
    :visible="isAddNewJobSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-job-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Job
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Entity -->
          <validation-provider
            #default="validationContext"
            name="Entity"
            rules="required"
          >
            <b-form-group
              label="Entity"
              label-for="entity"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="jobData.job_entity_name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="entityLists"
                :clearable="false"
                input-id="entity"
                @input="getQuotations"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Quotation -->
          <validation-provider
            #default="validationContext"
            name="Quotation"
            rules="required"
          >
            <b-form-group
              label="Quotation"
              label-for="quotation"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="jobData.job_quotation_no"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="quotationLists"
                :clearable="false"
                input-id="quotation"
                @input="setSelectedQuotation"
              />
              
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>

              <div v-if="jobData.customerCode != null"><label style="font-weight:bold;">Customer Code:</label><input style="border:none;background:none;" v-model="jobData.customerCode" disabled /><br /></div>
              <div v-if="jobData.customerName != null"><label style="font-weight:bold;">Customer Name:</label><input style="border:none;background:none;" v-model="jobData.customerName" disabled /><br /></div>
              <div v-if="jobData.customerContactName != null"><label style="font-weight:bold;">Contact Name:</label><input style="border:none;background:none;" v-model="jobData.customerContactName" disabled /><br /></div>
              <div v-if="jobData.customerContactNo != null"><label style="font-weight:bold;">Contact No:</label><input style="border:none;background:none;" v-model="jobData.customerContactNo" disabled /><br /></div>
              <div v-if="jobData.salesAgent != null"><label style="font-weight:bold;">Sales Agent:</label><input style="border:none;background:none;" v-model="jobData.salesAgent" disabled /><br /></div>
              <div v-if="jobData.totalAmount != null"><label style="font-weight:bold;">Total Amount:</label><input style="border:none;background:none;" v-model="jobData.totalAmount" disabled /><br /></div>

            </b-form-group>
          </validation-provider>
          <b-form-group
              label="Address"
              label-for="address"
            >
               <b-form-input
                id="address"
                v-model="jobData.job_address"
                placeholder="Enter something..." 
                @change="getPostalCode()"
              ></b-form-input>
              
            </b-form-group> 
            <validation-provider
            #default="validationContext"
            name="Postal Code"
            rules="required"
          >
            <b-form-group
              label="Postal Code"
              label-for="postalcode"
            >
               <b-form-input
                id="postalcode"
                v-model.lazy="jobData.job_postal_code"
                placeholder="Enter a valid postal code..." 
                :disabled="disablePostalCodeInput" @change="verifiedPostalCode()"
              ></b-form-input>
              <span style="font-size:12px;font-style:italic;">{{getPostalCodeMessage}}</span>
              
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
 
            </b-form-group>
          </validation-provider> 
          <validation-provider
            #default="validationContext"
            name="Job Start Date"
            rules="required"
          >
            <b-form-group
              label="Job Start Date"
              label-for="job_start_date"
              :state="getValidationState(validationContext)"
            >  
            <b-form-datepicker v-model="jobData.job_start_date"
            :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
             ></b-form-datepicker>
         
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Job End Date"
            rules="required"
          >
            <b-form-group
              label="Job End Date"
              label-for="job_end_date"
              :state="getValidationState(validationContext)"
            >  
            <b-form-datepicker v-model="jobData.job_end_date"
            :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
             ></b-form-datepicker>
         
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
            <b-form-group
              label="Remark"
              label-for="remark"
            >
               <b-form-textarea
                id="remark"
                v-model="jobData.job_remark"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group> 
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import Vue from 'vue'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormTextarea,BFormDatepicker,BFormSelect
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
//import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BFormSelect,
    BFormDatepicker,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    vSelect, 
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewJobSidebarActive',
    event: 'update:is-add-new-job-sidebar-active',
  },
  props: {
    isAddNewJobSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email 
    }
  }, 
  mounted(){
   
   this.getEntities();
   
  },

  setup(props, { emit }) { 
    const quotations = ref([]); 
    const quotationLists = ref([]); 
    const entityLists = ref([]);
    const getPostalCodeMessage = ref('');
    const disablePostalCodeInput = ref(false);
    const workerList = ref([]);

    
    const verifiedPostalCode = () => {
      disablePostalCodeInput.value = true;
      getPostalCodeMessage.value = "Verifiying postal code...";
      
        store.dispatch('app-job/searchAddress',jobData.value.job_postal_code).then((response)=>{
          
          if(response.data != undefined)
          {  
            if(!isNaN(response.data.postal_code))
            {
              Vue.set(jobData.value,"job_postal_code",response.data.postal_code);
              Vue.set(jobData.value,"job_latitude",response.data.latitude);
              Vue.set(jobData.value,"job_longtitude",response.data.longtitude);
              getPostalCodeMessage.value = "";
            }
            else{
              getPostalCodeMessage.value = response.data.postal_code;
              Vue.set(jobData.value,"job_postal_code", "");
            }
          }
        });
        disablePostalCodeInput.value = false;
    };
    const getPostalCode = () => {
      disablePostalCodeInput.value = true;
      getPostalCodeMessage.value = "Loading postal code from address...";
            
        store.dispatch('app-job/searchAddress',jobData.value.job_address).then((response)=>{
          
          if(response.data != undefined)
          {  
            if(!isNaN(response.data.postal_code))
            {
              Vue.set(jobData.value,"job_postal_code",response.data.postal_code);
              Vue.set(jobData.value,"job_latitude",response.data.latitude);
              Vue.set(jobData.value,"job_longtitude",response.data.longtitude);
              getPostalCodeMessage.value = "";
            }
            else{
              getPostalCodeMessage.value = response.data.postal_code;
              Vue.set(jobData.value,"job_postal_code", "");
            }
          }
        });
        disablePostalCodeInput.value = false;
    };
    const setSelectedQuotation = () => {
      let vm = this;
      if(jobData.value.job_quotation_no != null)
      {
        
        quotations.value.filter(quotation => { 
          if(quotation.docNo.includes(jobData.value.job_quotation_no))
          {
              jobData.value.customerCode = quotation.debtorCode;
              jobData.value.customerName = quotation.debtorName;
              jobData.value.customerContactName = quotation.branchCode;
              jobData.value.customerContactNo = quotation.deliverPhone1;
              jobData.value.salesAgent = quotation.salesAgent;
              jobData.value.totalAmount = quotation.currencyCode +" " +quotation.finalTotal;

              let address = quotation.description.split("#");
              jobData.value.job_address = quotation.description; 
              if(address.length > 0)
              {
                getPostalCode(address[0]);
              } 

          }
        });        
      }
              
    };
    const getEntities = () => {
      store.dispatch('app-job/fetchEntities',"").then((response)=>{
        if(response.data.length > 0)
        {
          for(let i=0;i<response.data.length;i++)
          {
            if(!entityLists.value.includes(response.data[i].entity_conf_name))
              {
                entityLists.value.push(response.data[i].entity_conf_name);
              }
          }
        }
      });
    };
    const getQuotations = () => {
 
      store.dispatch('app-job/fetchQuotes',jobData.value.job_entity_name).then((response)=>{
        if(response.data.length > 0)
        {
          quotations.value = response.data;
          for(let i=0;i<response.data.length;i++)
          {
            if(!quotationLists.value.includes(response.data[i].docNo))
            {
                quotationLists.value.push(response.data[i].docNo);
            }
          }          
        }
      });

    };

    const blankJobData = {
      
    }

    const jobData = ref(JSON.parse(JSON.stringify(blankJobData)))
    const resetjobData = () => {
      jobData.value = JSON.parse(JSON.stringify(blankJobData))
    }

    const onSubmit = () => {
      
      let userData = JSON.parse(localStorage.getItem('userData'));
      jobData.value.job_created_by = userData.user_id;
      
      store.dispatch('app-job/addJob', jobData.value)
        .then((response) => {
          emit('refetch-data')
          emit('update:is-add-new-job-sidebar-active', false)
        });
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetjobData)

    return {
      workerList,
      verifiedPostalCode,
      disablePostalCodeInput,
      getPostalCodeMessage,
      quotationLists,
      jobData,
      onSubmit,
      entityLists,
      getEntities,
      getQuotations,
      quotations,
      setSelectedQuotation,
      refFormObserver,
      getValidationState,
      resetForm,
      getPostalCode,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-job-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
